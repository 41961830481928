import $ from "jquery";
window.$ = $;
import JSZip from "jszip";
import DataTable from "datatables.net-dt";
import "datatables.net-buttons-dt";
import "datatables.net-buttons/js/buttons.html5";

window.JSZip = JSZip;

function initDataTable() {
    if (document.querySelector('body.print')) { // Don't initialize DataTables on print page
        return;
    }

    if (document.querySelectorAll('.browse-table').length === 0) {
        return;
    }

    const titleIsEmtpy = window.dynamicViewTitle === null || window.dynamicViewTitle === "";
    const fileName = `Yard Tower${titleIsEmtpy ? "" : " - " + window.dynamicViewTitle}`;
    const tableExportOptions = { columns: "thead th:not(.no-export)" };

    const orderSetting = document.querySelectorAll('.browse-table th').length > 3 ? [[3, "asc"]] : [];

    const layout = {
        topStart: 'search',
        topEnd: {
            features: ['buttons', 'info']
        },
        bottomStart: null,
        bottomEnd: null,
    }
    const settings = {
        order: orderSetting,
        stateSave: true,
        scrollCollapse: true,
        scrollY: document.querySelector(".browse-container").offsetHeight - 84,
        scrollResize: true,
        scrollX: true,
        paging: false,
        lengthChange: false,
        buttons: [
            {
                extend: "collection",
                fade: false,
                text: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M6 13h4v-7h4v7h4l-6 6-6-6zm16-1c0 5.514-4.486 10-10 10s-10-4.486-10-10 4.486-10 10-10 10 4.486 10 10zm2 0c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12z"/></svg><span class="d-none d-md-inline-block">Download</span>',
                buttons: [
                    {
                        text: "PDF-document (.pdf)",
                        action: () => {
                            window.open(window.printUrl, '_blank');
                        }
                    },
                    {
                        extend: "excelHtml5",
                        exportOptions: tableExportOptions,
                        filename: fileName,
                        text: "Microsoft Excel (.xlsx)",
                    },
                    {
                        extend: "csvHtml5",
                        exportOptions: tableExportOptions,
                        filename: fileName,
                        text: "CSV-document (.csv)",
                    },
                ],
            },
        ],
        layout: layout,
        language: dataTableLanguage,
        columnDefs: [
            { width: 30, targets: 0, orderable: false },
            { width: 30, targets: 1, orderable: false },
        ],
    };
    const table = new DataTable(".browse-table", settings);

    table.ready(() => {
        const loadingIndicator = document.getElementById("browse-loading");
        loadingIndicator.classList.add("d-none");
        loadingIndicator.classList.remove("d-flex");
    });

    // Add data-index for components/ObjectModal.vue
    table.on("order.dt search.dt", function () {
        table.rows()
            .nodes()
            .each((row, i) => {
                row.setAttribute("data-index", i + 1);
            });
    }).draw();

    $(document)
        .off("click", ".browse-table tbody tr")
        .on("click", ".browse-table tbody tr", function () {
            const self = $(this);
            if (!self.hasClass("selected")) {
                table.$("tr.selected").removeClass("selected");
                self.addClass("selected");
            }
        });
}

function stopPropagationOnDropDownMenuClick() {
    $(".dropdown-menu").on("click", function (e) {
        e.stopPropagation();
    });
}

$(function () {
    initDataTable();
    stopPropagationOnDropDownMenuClick();
});
