<template>
    <modal @before-open="beforeOpen" @before-close="beforeClose" @open-item-viewer="loadActiveElement()"
        class="viewer transparent" name="object-viewer" width="100%" height="100%">
        <button @click="$modal.hide('object-viewer')" class="viewer-close">
            <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="#313E4D"
                    d="M15.71,8.29a1,1,0,0,0-1.42,0L12,10.59,9.71,8.29A1,1,0,0,0,8.29,9.71L10.59,12l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L13.41,12l2.3-2.29A1,1,0,0,0,15.71,8.29Zm3.36-3.36A10,10,0,1,0,4.93,19.07,10,10,0,1,0,19.07,4.93ZM17.66,17.66A8,8,0,1,1,20,12,7.95,7.95,0,0,1,17.66,17.66Z" />
            </svg>
        </button>
        <div class="viewer-container">
            <div class="viewer-main">
                <div class="viewer-figure">
                    <div class="alert alert-danger" v-if="error">
                        {{ error.message }}
                    </div>
                    <img v-if="!error && currentImage" alt="" class="viewer-image" :src="currentImage" />
                </div>

                <svg style="max-width: 128px" v-if="isLocked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="#d7dce0"
                        d="M17,9V7A5,5,0,0,0,7,7V9a3,3,0,0,0-3,3v7a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V12A3,3,0,0,0,17,9ZM9,7a3,3,0,0,1,6,0V9H9Zm9,12a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H17a1,1,0,0,1,1,1Z" />
                </svg>

                <svg style="max-width: 128px" v-if="showImagePlaceholder" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24">
                    <path fill="#d7dce0"
                        d="M19.5,4H10a1,1,0,0,0,0,2H19.5a1,1,0,0,1,1,1v6.76l-1.88-1.88a3,3,0,0,0-1.14-.71,1,1,0,1,0-.64,1.9.82.82,0,0,1,.36.23l3.31,3.29a.66.66,0,0,0,0,.15.83.83,0,0,0,0,.15,1.18,1.18,0,0,0,.13.18.48.48,0,0,0,.09.11.9.9,0,0,0,.2.14.6.6,0,0,0,.11.06.91.91,0,0,0,.37.08,1,1,0,0,0,1-1V7A3,3,0,0,0,19.5,4ZM3.21,2.29A1,1,0,0,0,1.79,3.71L3.18,5.1A3,3,0,0,0,2.5,7V17a3,3,0,0,0,3,3H18.09l1.7,1.71a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42ZM4.5,7a1,1,0,0,1,.12-.46L7.34,9.25a3,3,0,0,0-1,.63L4.5,11.76Zm1,11a1,1,0,0,1-1-1V14.58l3.3-3.29a1,1,0,0,1,1.4,0L15.91,18Z" />
                </svg>
            </div>
            <ViewerSidebar :details-is-visible="detailsIsVisible" :id="id" :info="info" :language="language" />

            <ThumbnailBar :images="images" v-on:toggle-image="toggleImage" />

            <BottomBar :prev="prev" :next="next" :pagination="pagination" @prev-item="loadPrevNext(directionPrev)"
                @next-item="loadPrevNext(directionNext)" />
        </div>
    </modal>
</template>
<script lang="ts">
import ViewerSidebar from "../components/ViewerSidebar.vue";
import ThumbnailBar from "../components/ObjectModal/ThumbnailBar.vue";
import BottomBar from "../components/ObjectModal/BottomBar.vue";
import { SingleImage } from "../types/single-image";
import { objectModalLanguage } from "../interfaces/object-modal";
import { ImageTags } from "../enums/image-tags";

interface ObjectModalData {
    selectedImage?: string;
    index?: number;
    total?: number;
    id?: string;
    info?: {
        image: Array<SingleImage>;
    };
    loading: boolean;
    language: objectModalLanguage;
    detailsIsVisible: boolean;
    modalIsVisible: boolean;
    prev?: string;
    next?: string;
    responseStatus?: number;
    directionPrev: NavigationDirections;
    directionNext: NavigationDirections;
}

enum NavigationDirections {
    Prev = "prev",
    Next = "next",
}

export default {
    name: "ObjectModal",
    components: {
        ViewerSidebar,
        ThumbnailBar,
        BottomBar,
    },
    data(): ObjectModalData {
        return {
            selectedImage: undefined,
            index: undefined,
            total: undefined,
            id: undefined,
            info: undefined,
            loading: false,
            language: window.objectModalLanguage,
            detailsIsVisible: false,
            modalIsVisible: false,
            prev: undefined,
            next: undefined,
            responseStatus: undefined,
            directionPrev: NavigationDirections.Prev,
            directionNext: NavigationDirections.Next,
        };
    },
    created() {
        const vm = this;

        this.$eventBus.$on("item-viewer-open", () => {
            vm.loadActiveElement();
        });

        window.addEventListener("keydown", (e) => {
            if (flagViewerNext === 0) {
                this.keyEvents(e);
            }
        });
    },
    computed: {
        isLocked() {
            return this.info && "code" in this.info && this.info.code === 402;
        },
        showImagePlaceholder() {
            return this.info && !this.currentImage && !this.isLocked;
        },
        hasImage() {
            return typeof this.featuredImage !== 'undefined';
        },
        currentImage() {
            if (!this.info) {
                return null;
            }

            if (
                typeof this.selectedImage === "undefined" &&
                this.featuredImage
            ) {
                return this.featuredImage.sizes.large;
            }

            if (
                typeof this.selectedImage === "undefined" &&
                this.images.length > 0
            ) {
                return this.images[0].sizes.large;
            }

            return this.selectedImage;
        },
        images() {
            if (!this.info || !this.info.image) {
                return [];
            }

            return this.info.image;
        },
        featuredImage() {
            if (!this.info || !this.info.image) {
                return undefined;
            }

            return this.info.image.find((image) =>
                image.tags.includes(ImageTags.FEATURED),
            );
        },
        error() {
            if (this.responseStatus === 403) {
                return {
                    status: this.responseStatus,
                    message: this.language.object_access_forbiddden,
                };
            }

            return null;
        },
    },
    methods: {
        keyEvents(e: KeyboardEvent) {
            const vm = this;

            if (flagViewerNext === 1) {
                // We are using the next version of the item viewer, do not capture key events here
                return;
            }

            if (e.code === "Enter" && !this.modalIsVisible) {
                vm.loadActiveElement();
                e.preventDefault();
            }

            if (e.code === "ArrowRight" && this.modalIsVisible) {
                vm.loadPrevNext(NavigationDirections.Next);
                e.preventDefault();
            }

            if (e.code === "ArrowLeft" && this.modalIsVisible) {
                vm.loadPrevNext(NavigationDirections.Prev);
                e.preventDefault();
            }
        },
        toggleImage: function (image: string) {
            this.selectedImage = image;
        },
        getStoredInformationPaneState: function () {
            const storedVal = localStorage.object_modal_show_information;
            return storedVal === null ? false : storedVal === "true";
        },
        pagination: function () {
            return `${this.index} ${this.language.of} ${this.total}`;
        },
        setIndex: function (index: number) {
            this.index = index;
            this.total = document.querySelectorAll(".browse-entry").length;
        },
        getPrevNext: (): { prev?: string; next?: string } => {
            const elements = document.querySelectorAll(".browse-entry"),
                selectedEl = document.querySelector(
                    ".selected",
                ) as HTMLInputElement,
                selectedId = selectedEl.getAttribute("data-id");

            let prev = undefined,
                next = undefined;

            // Find next and previous element
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].getAttribute("data-id") === selectedId) {
                    prev = elements[i - 1];
                    next = elements[i + 1];

                    prev =
                        typeof prev === "undefined"
                            ? undefined
                            : prev.getAttribute("data-id") ?? undefined;
                    next =
                        typeof next === "undefined"
                            ? undefined
                            : next.getAttribute("data-id") ?? undefined;
                }
            }

            return {
                prev: prev,
                next: next,
            };
        },
        loadPrevNext(direction: NavigationDirections) {
            this.selectedImage = undefined;
            const vm = this;
            const btn = document.getElementById(`viewer-${direction}`);
            const selectedRow = document.querySelector(".selected");

            if (!vm.modalIsVisible || btn === null) {
                return false;
            }

            const prevNext = this.getPrevNext();

            // Stop when there is no next
            if (!prevNext[direction]) {
                return false;
            }

            btn.classList.add("active");
            setTimeout(() => {
                btn.classList.remove("active");
            }, 1);

            // Unset active element
            if (selectedRow) {
                selectedRow.classList.remove("selected");
            }

            // Set new active element
            const newSelectedRow = document.querySelector(
                `[data-id="${prevNext[direction]}"]`,
            );

            if (newSelectedRow) {
                newSelectedRow.classList.add("selected");
            }

            const newObject = prevNext[direction];
            if (newObject) {
                this.loadObject(newObject);
            }
        },
        loadActiveElement() {
            const el = document.querySelector(".selected");

            if (typeof el === "undefined" || el === null) {
                return false;
            }

            const id = el.getAttribute("data-id");

            if (id) {
                this.loadObject(id);

                this.$modal.show("object-viewer");
                this.modalIsVisible = true;
            }
        },
        beforeOpen() {
            // Disable scroll on body
            document.body.classList.add("noscroll");
        },
        beforeClose() {
            this.info = undefined;
            this.id = undefined;
            this.selectedImage = undefined;
            this.modalIsVisible = false;
            this.detailsIsVisible = false;

            // Remove selected element when in `grid` mode
            const selectedElement = document.querySelector(".selected");
            if (
                document.querySelector(".browse-grid") !== null &&
                selectedElement !== null
            ) {
                selectedElement.classList.remove("selected");
            }

            // Enable scroll on body
            document.body.classList.remove("noscroll");
        },
        loadObject: function (id: string) {
            let self = this;

            // Reset
            self.info = undefined;

            // Update prev / next buttons
            const pagination = self.getPrevNext();
            self.prev = pagination.prev;
            self.next = pagination.next;

            // Get table row data
            const tableRow = document.querySelector(`[data-id="${id}"]`);

            if (tableRow === null) {
                return;
            }

            const tableRowIndex = tableRow.getAttribute("data-index");

            if (tableRowIndex === null) {
                return;
            }

            this.setIndex(parseInt(tableRowIndex, 10));

            // Append listing ID so we can check if this member has access to the listing
            const view =
                typeof window.sharedDynamicViewId === "undefined"
                    ? ""
                    : `?view=${window.sharedDynamicViewId}`;

            window.axios
                .get(`/api/v1/items/${id}${view}`)
                .then((response) => {
                    self.responseStatus = response.status;
                    const result = response.data.data;
                    self.info = result.attributes;
                    self.id = result.id;

                    this.$eventBus.$emit("item-viewer-load");
                })
                .catch((error) => {
                    self.responseStatus = error.response.status;
                });
        },
    },
};
</script>

<style lang="scss">
.info {
    &-row {

        &:hover {
            background: #f4f5f5;
        }
    }

    &-label {
        flex: 0 0 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-right: 0.5rem;
    }
}

.viewer {
    .vm--modal {
        background: #f5f8fa;
        border-radius: 0;
    }

    &-container {
        display: flex;
        height: 100%;
    }

    &-main {
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;
        margin: 6rem auto;
        width: 100%;

        @media (min-width: 62rem) {
            margin: 7.5rem 2.5rem;
            flex: 1 30.625rem;
        }
    }

    &-title {
        font-size: 1.75rem;
        margin-bottom: 0.5rem;
        line-height: 2rem;
        font-weight: 700;
    }

    &-subtitle {
        font-size: 1.25rem;
        margin: 0 0 2rem;
    }

    &-row {
        margin-bottom: 1rem;
    }

    &-more {
        color: #4771d0;
        font-size: 14px;
        border: 0;
        background: transparent;
        margin: 0 0 1em 0;
        padding: 0;

        svg {
            transition: transform 200ms;
        }

        &.active svg {
            transform: rotate(180deg);
        }

        &:hover {
            color: #005ffb;
            text-decoration: none;

            svg {
                fill: #005ffb;
            }
        }

        &:focus {
            outline: none;
        }
    }

    &-subtitle,
    &-label {
        opacity: 0.75;
    }

    &-side,
    &-bar {
        box-sizing: border-box;
        background: white;

        @media (min-width: 992px) {
            border-left: 1px solid #d7dce0;
        }
    }

    &-figure {
        align-items: center;
        display: flex;
        height: 100%;
    }

    &-image {
        max-width: 100%;
        max-height: 100%;
        margin: auto;
    }

    &-side {
        color: #313e4d;
        font-size: 14px;

        @media (min-width: 992px) {
            flex: 0 491px;
            display: flex;
        }
    }

    &-data {
        width: 100%;
        padding: 32px;
        display: none;
        overflow-y: auto;
        overflow-x: hidden;

        @media (max-width: 991px) {
            &.active {
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 70px;
                top: 0;
                z-index: 11;
                background: white;
                padding: 85px 20px 100px;
                outline: none;
            }
        }

        @media (min-width: 992px) {
            display: block;
            width: 430px;
        }
    }

    &-bar {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 70px;
        display: flex;
        align-items: center;
        border-top: 1px solid #d7dce0;

        @media (min-width: 992px) {
            align-items: inherit;
            height: inherit;
            flex-direction: column;
            justify-content: inherit;
            position: relative;
            padding-top: 10px;
            width: 60px;
            border-top: 0;
        }

        &-icon {
            width: 24px;
            height: 24px;
        }

        &-spacer {
            @media (min-width: 992px) {
                height: 1px;
                position: relative;
                margin: 10px 0;
            }

            &::after {
                content: "";
                border-bottom: 1px solid #d7dce0;
                height: 1px;
                display: block;
                box-sizing: border-box;
                position: absolute;
                left: 10px;
                right: 10px;
                bottom: 0;
            }
        }

        &-btn {
            align-items: center;
            display: flex;
            background: transparent;
            border: 0;
            height: 3.75rem;
            justify-content: center;
            width: 100%;
            flex-direction: column;
            transition: opacity 150ms;
            opacity: 1;

            &:disabled {
                opacity: 0.5;
            }

            &:focus {
                outline: none;

                @media (min-width: 992px) {
                    outline: inherit;
                }
            }

            &-md-none {
                @media (min-width: 992px) {
                    display: none;
                }
            }

            &.active .viewer-bar-btn-label {
                color: #4670d0;
            }

            &.active svg path,
            &:hover svg {
                fill: #4670d0;
            }

            &-label {
                color: #444;
                font-size: 12px;
                margin-top: 5px;

                @media (min-width: 992px) {
                    display: none;
                }
            }
        }
    }

    &-close {
        background: white;
        display: flex;
        align-items: center;
        position: absolute;
        left: 15px;
        top: 15px;
        padding: 8px 12px;
        border-radius: 4px;
        border: 0;
        font-weight: bold;
        box-shadow:
            0 0.3px 0.5px rgba(0, 0, 0, 0.044),
            0 0.9px 1.8px rgba(0, 0, 0, 0.066),
            0 4px 8px rgba(0, 0, 0, 0.11);
        z-index: 30;


        &:focus {
            outline: none;
        }
    }
}
</style>
